import React from 'react'
import styled from 'styled-components';
import { Button } from '../components/Button';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import logo from "../images/logo.svg"
import { GlobalStyle } from '../components/styles/GlobalStyles';
import SEO from '../components/seo';

const DA_LandingPage = () => {
    const data = useStaticQuery(graphql`
        query DALandingPageQuery {
            allLandingPagesJson {
            edges {
                node {
                alt
                img {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
            }
        }
      
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }

    return (
        <Container>
            <SEO title="Data Analytics company in Nairobi Kenya sign up"
                description="We offer consultations in healthcare data analytics, machine learning and artificial intelligence in Nairobi Kenya, sign up"
                keywords="data analytics, artificial intelligence"
            >
            </SEO>
            <GlobalStyle />
            <img src={logo} alt="logo" css={`width: 130px; height: 130px;position: absolute; top: -30px; left: 10px`} />
            <CtaWrapper>
                <ContainerP>How You Generate Insight From Your Healthcare Data</ContainerP>
                <ContainerP2>1. Data Visualization Dashboards</ContainerP2>
                <ContainerP2>2. Forecasting data models</ContainerP2>
                <ContainerP2>3. Statistical data analysis</ContainerP2>
                <form name="data-analytics sign up" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="data-analytics sign up" />
                    <FormWrap>
                        <label htmlFor="email">
                            <ContainerP3>Enter Your Email Below</ContainerP3>
                            <input type="email" id="email" name="email" />
                        </label>
                        <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Sign up for a FREE Consultation</Button>
                    </FormWrap>
                </form>
            </CtaWrapper>
            <Hero>
                {getImage(data)}
            </Hero>

        </Container>
    )
}

export default DA_LandingPage

const Container = styled.div`
    display: grid;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;   
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);;
`
const CtaWrapper = styled.div`
    grid-row: 2;
    grid-column: span 12;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    //align-self: center;

    @media screen and (min-width: 800px) {
        grid-row: 2;
        grid-column: span 6;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;
        //max-width: 80%;
    }
                
    @media screen and (min-width: 1200px) {
        grid-row: 2;
        grid-column: span 4;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;
        max-width: 80%;
    }
`
const Hero = styled.div`
    grid-column: span 12;
    grid-row: 1;
    margin-top: 2rem;

    @media screen and (min-width: 600px) {
        grid-column: span 12;
        grid-row: 1;
        //margin-top: -1rem;
    }

    @media screen and (min-width: 800px) {
        grid-column: span 6;
        grid-row: 2;
    }
    
    @media screen and (min-width: 1200px) {
        grid-column: span 8;
        grid-row: 2;
    }

`

const Image = styled(Img)`
    width:100%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 600px) {
        width: 75%;    
       }    

    @media screen and (min-width: 800px) {
     width:100%;
         
    }
    @media screen and (min-width: 1200px) {
     width:80%;
         
    }

`
const ContainerP = styled.h1`
    font-size: clamp(2rem,2vw, 2.25rem);
    //text-align: center;
    color: #fff;
    margin-bottom: 1rem;
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    //text-align: center;
    color: #fff;
    margin-bottom: 1rem;
`
const ContainerP3 = styled.p`
    font-size: clamp(0.8rem,2vw, 1rem);
    text-align: center;
    color: #fff;
`
const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
   

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }
`


